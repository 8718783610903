.p-picklist {
  .p-picklist-list {
    .p-picklist-item {
      &:focus {
        background: $emphasis-lower;

        &.p-highlight {
          background: rgba($primaryColor, 0.24);
        }
      }
    }
  }
}
