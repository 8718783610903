.p-paginator {
  //justify-content: flex-end;

  .p-paginator-element {
    &:focus {
      background: $emphasis-lower;

      &.p-highlight {
        background: rgba($primaryColor, 0.24);
      }
    }
  }
}
