.word-break-break-word {
  word-break: break-word;
}

.word-break-break-all {
  word-break: break-all;
}

.white-space-pre-line {
  white-space: pre-line;
}

.white-space.pre-wrap {
  white-space: pre-wrap;
}

.poppins {
  font-family: 'Poppins', sans-serif;
}
