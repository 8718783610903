p-inputmask.ng-touched.ng-invalid {
  .p-inputtext {
    &:enabled:focus {
      box-shadow:
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor;
    }
  }
}

p-inputmask.ng-touched.ng-invalid > .p-inputtext {
  @include invalid-input();
}
