.p-accordion {
  border-radius: 0;
  box-shadow: none;
  .p-accordion-tab {
    &:first-child {
      .p-accordion-header {
        .p-accordion-header-link {
          border-radius: 0;
        }
      }
    }

    &:last-child {
      .p-accordion-header:not(.p-highlight) {
        .p-accordion-header-link {
          border-radius: 0;
        }
      }

      .p-accordion-content {
        border-radius: 0;
      }
    }
    .p-accordion-header-link {
      cursor: pointer;
      &:focus {
        box-shadow: none !important;
      }
    }
    box-shadow: none;
    border-radius: 0;
    .p-accordion-content {
      box-shadow: none;
      border-radius: 0;
    }
    .p-accordion-toggle-icon {
      order: initial;
      margin-left: 0;
    }
  }
}
