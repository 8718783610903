/* Customizations to the designer theme should be defined here */

@import './custom-extensions/_accordion';
@import './custom-extensions/badge';
@import './custom-extensions/calender';
@import './custom-extensions/chips';
@import './custom-extensions/chips';
@import './custom-extensions/datatable';
@import './custom-extensions/dialog';
@import './custom-extensions/error';
@import './custom-extensions/filterbutton';
@import './custom-extensions/height';
@import './custom-extensions/menu';
@import './custom-extensions/multiSelect';
@import './custom-extensions/selectbutton';
@import './custom-extensions/tabs';
@import './custom-extensions/typography';
@import './custom-extensions/visibility';
@import './custom-extensions/width';
@import './custom-extensions/autocomplete';
@import './custom-extensions/cascadeselect';
@import './custom-extensions/drowndown';
@import './custom-extensions/inputmask.scss';
@import './custom-extensions/inputnumber.scss';
@import './custom-extensions/inputtext';
@import './custom-extensions/inputswitch';
@import './custom-extensions/listbox';
@import './custom-extensions/multiSelect';
@import './custom-extensions/radiobutton';
@import './custom-extensions//togglebutton';
