@import './theme-base/_colors';

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

//---------Theme----------------------
$primaryColor: $pink-500 !default;
$primaryTextColor: #ffffff !default;
//selected state
$highlightBg: $pink-50 !default;
$highlightTextColor: $primaryColor !default;

$accentColor: #333 !default;
$accentTextColor: #ffffff !default;

$colors: (
  // default colors
  'blue': #2196f3,
  'green': #28ddcf,
  'yellow': #fbc02d,
  'cyan': #28ddcf,
  'pink': #e91e63,
  'indigo': #4e5fbb,
  'teal': #009688,
  'orange': #ff9800,
  'bluegray': #607d8b,
  'purple': #9c27b0,
  'red': #f44336,
  'primary': $primaryColor,
  // custom colors
  'white': #f6f5f8,
  'black': #333333
) !default;

$emphasis-high: rgba(0, 0, 0, 0.87) !default;
$emphasis-medium: rgba(0, 0, 0, 0.6) !default;
$emphasis-low: rgba(0, 0, 0, 0.38) !default;
$emphasis-lower: rgba(0, 0, 0, 0.12) !default;
$overlayColor: #000000 !default;

//-------------Global-----------------
$fontFamily: 'Nunito' sans-serif !default;
$fontSize: 1rem !default;
$fontWeight: normal !default;
$textColor: $gray-800 !default;
$textSecondaryColor: $gray-500 !default;
$borderRadius: 4px !default;
$transitionDuration: 0.2s !default;
$formElementTransition:
  background-color $transitionDuration,
  border-color $transitionDuration,
  color $transitionDuration,
  box-shadow $transitionDuration,
  background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1) !default;
$actionIconTransition:
  background-color $transitionDuration,
  color $transitionDuration,
  box-shadow $transitionDuration !default;
$listItemTransition: none !default;
$primeIconFontSize: 1rem !default;

$inlineSpacing: 0.5rem !default;
$disabledOpacity: 0.38 !default;
$maskBg: transparentize($gray-900, 0.4) !default;
$loadingIconFontSize: 2rem !default;
$errorColor: $red-500 !default;

//divider
$dividerHorizontalMargin: 0.5rem 0 !default;
$dividerHorizontalPadding: 0 0.5rem !default;
$dividerVerticalMargin: 0 0.5rem !default;
$dividerVerticalPadding: 0.5rem 0 !default;
$dividerSize: 1px !default;
$dividerColor: $gray-200 !default;
$divider: 1px solid $dividerColor !default;

//scale
$scaleSM: 0.875 !default;
$scaleLG: 1.25 !default;

//focus
$focusOutlineColor: $pink-300 !default;
$focusOutline: 0 none !default;
$focusOutlineOffset: 0 !default;
$focusShadow: 0 0 0 2.8px rgba($pink-900, 0.1) !default;

//action icons
$actionIconWidth: 1rem !default;
$actionIconHeight: 1rem !default;
$actionPadding: 1rem !default;
$actionIconBg: transparent !default;
$actionIconBorder: 0 none !default;
$actionIconColor: $textSecondaryColor !default;
$actionIconHoverBg: $gray-200 !default;
$actionIconHoverBorderColor: transparent !default;
$actionIconHoverColor: $gray-900 !default;
$actionIconBorderRadius: 0.25rem !default;

//-------------Form-----------------
//calendar
$calendarTableMargin: 0 !default;
$calendarPadding: 0 !default;
$calendarBg: $white !default;
$calendarInlineBg: $calendarBg !default;
$calendarTextColor: $textColor !default;
$calendarBorder: $divider !default;
$calendarOverlayBorder: transparent !default;

$calendarHeaderPadding: 0.5rem !default;
$calendarHeaderBg: $calendarBg !default;
$calendarInlineHeaderBg: $calendarBg !default;
$calendarHeaderBorder: $divider !default;
$calendarHeaderTextColor: $textColor !default;
$calendarHeaderFontWeight: 600 !default;
$calendarHeaderCellPadding: 0.5rem !default;
$calendarMonthYearHeaderHoverTextColor: $primaryColor !default;

$calendarCellDatePadding: 0.5rem !default;
$calendarCellDateWidth: 2.25rem !default;
$calendarCellDateHeight: 2.25rem !default;
$calendarCellDateBorderRadius: 50% !default;
$calendarCellDateBorder: 1px solid transparent !default;
$calendarCellDateHoverBg: $gray-50 !default;
$calendarCellDateTodayBg: $gray-100 !default;
$calendarCellDateTodayBorderColor: transparent !default;
$calendarCellDateTodayTextColor: $textColor !default;

$calendarButtonBarPadding: 1rem 0 !default;
$calendarTimePickerPadding: 0 0.5rem !default;
$calendarTimePickerElementPadding: 0 !default;
$calendarTimePickerTimeFontSize: 1.25rem !default;

$calendarBreakpoint: 769px !default;
$calendarCellDatePaddingSM: 0 !default;

//checkbox
$checkboxWidth: 1.25rem !default;
$checkboxHeight: 1.25rem !default;
$checkboxBorder: 2px solid $gray-200 !default;
$checkboxIconFontSize: 1rem !default;
$checkboxActiveBorderColor: $primaryColor !default;
$checkboxActiveBg: $primaryColor !default;
$checkboxIconActiveColor: $white !default;
$checkboxActiveHoverBg: $pink-600 !default;
$checkboxIconActiveHoverColor: $checkboxIconActiveColor !default;
$checkboxActiveHoverBorderColor: $checkboxActiveHoverBg !default;

//colorpicker
$colorPickerPreviewWidth: 1.75rem !default;
$colorPickerPreviewHeight: 1.75rem !default;
$colorPickerBg: $gray-800 !default;
$colorPickerBorder: 1px solid $colorPickerBg !default;
$colorPickerHandleColor: $white !default;

//editor
$editorToolbarBg: $gray-50 !default;
$editorToolbarBorder: $divider !default;
$editorToolbarPadding: 1rem !default;
$editorToolbarIconColor: $textSecondaryColor !default;
$editorToolbarIconHoverColor: $gray-700 !default;
$editorIconActiveColor: $primaryColor !default;
$editorContentBorder: $editorToolbarBorder !default;
$editorContentBg: $white !default;

//input groups
$inputGroupBg: $gray-50 !default;
$inputGroupTextColor: $textColor !default;
$inputGroupAddOnMinWidth: 2.5rem !default;

//input switch
$inputSwitchWidth: 2rem !default;
$inputSwitchHeight: 1rem !default;
$inputSwitchBorderRadius: 0.5rem !default;
$inputSwitchHandleWidth: 1rem !default;
$inputSwitchHandleHeight: 1rem !default;
$inputSwitchHandleBorderRadius: 50% !default;
$inputSwitchSliderPadding: 0 0.25rem !default;
$inputSwitchSliderOffBg: $gray-200 !default;
$inputSwitchHandleOffBg: $white !default;
$inputSwitchSliderOffHoverBg: $gray-300 !default;
$inputSwitchSliderOnBg: $pink-500 !default;
$inputSwitchSliderOnHoverBg: $pink-600 !default;
$inputSwitchHandleOnBg: $inputSwitchHandleOffBg !default;

//input field (e.g. inputtext, spinner, inputmask)
$inputPadding: 0.5rem 1rem !default;
$inputTextFontSize: 1rem !default;
$inputBg: $white !default;
$inputTextColor: $textColor !default;
$inputIconColor: $gray-700 !default;
$inputBorder: $divider !default;
$inputHoverBorderColor: $gray-400 !default;
$inputFocusBorderColor: $inputHoverBorderColor !default;
$inputErrorBorderColor: $errorColor !default;
$inputPlaceholderTextColor: $textSecondaryColor !default;
$inputFilledBg: $gray-100 !default;
$inputFilledHoverBg: $gray-200 !default;
$inputFilledFocusBg: $inputBg !default;

//input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$inputListBg: $inputBg !default;
$inputListTextColor: $textColor !default;
$inputListBorder: $divider !default;
$inputListPadding: 0.5rem 0 !default;
$inputListItemPadding: 0.75rem 1rem !default;
$inputListItemBg: transparent !default;
$inputListItemTextColor: $textColor !default;
$inputListItemHoverBg: $gray-100 !default;
$inputListItemTextHoverColor: $textColor !default;
$inputListItemBorder: 0 none !default;
$inputListItemBorderRadius: 0 !default;
$inputListItemMargin: 0 !default;
$inputListItemFocusShadow: none !default;
$inputListHeaderPadding: 0.5rem 1rem !default;
$inputListHeaderMargin: 0 !default;
$inputListHeaderBg: $inputBg !default;
$inputListHeaderTextColor: $textColor !default;
$inputListHeaderBorder: $inputListBorder !default;

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBg: $inputBg !default;
$inputOverlayHeaderBg: $gray-50 !default;
$inputOverlayBorder: 0 none !default;
$inputOverlayShadow:
  0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12) !default;

//password
$passwordMeterBg: $gray-200 !default;
$passwordWeakBg: $red-300 !default;
$passwordMediumBg: $yellow-300 !default;
$passwordStrongBg: $green-300 !default;

//radiobutton
$radiobuttonWidth: 1.25rem !default;
$radiobuttonHeight: 1.25rem !default;
$radiobuttonBorder: 2px solid $gray-200 !default;
$radiobuttonIconSize: 0.75rem !default;
$radiobuttonActiveBg: $primaryColor !default;
$radiobuttonActiveBorderColor: $radiobuttonActiveBg !default;
$radiobuttonIconActiveColor: $white !default;
$radiobuttonActiveHoverBg: $pink-600 !default;
$radiobuttonActiveHoverBorderColor: $radiobuttonActiveHoverBg !default;
$radiobuttonIconActiveHoverColor: $radiobuttonIconActiveColor !default;

//rating
$ratingIconFontSize: 1rem !default;
$ratingCancelIconColor: $gray-300 !default;
$ratingCancelIconHoverColor: $gray-400 !default;
$ratingStarIconOffColor: $gray-800 !default;
$ratingStarIconOnColor: $pink-500 !default;
$ratingStarIconHoverColor: $pink-600 !default;

//slider
$sliderBg: $gray-200 !default;
$sliderBorder: 0 none !default;
$sliderHorizontalHeight: 0.25rem !default;
$sliderVerticalWidth: 0.25rem !default;
$sliderHandleWidth: 1rem !default;
$sliderHandleHeight: 1rem !default;
$sliderHandleBg: $white !default;
$sliderHandleBorder: 1px solid $primaryColor !default;
$sliderHandleBorderRadius: 50% !default;
$sliderHandleHoverBorderColor: $pink-600 !default;
$sliderHandleHoverBg: $pink-600 !default;
$sliderRangeBg: $primaryColor !default;

//togglebutton
$toggleButtonBg: $white !default;
$toggleButtonBorder: $divider !default;
$toggleButtonTextColor: $textColor !default;
$toggleButtonIconColor: $textSecondaryColor !default;
$toggleButtonHoverBg: $gray-50 !default;
$toggleButtonHoverBorderColor: $toggleButtonBorder !default;
$toggleButtonTextHoverColor: $toggleButtonTextColor !default;
$toggleButtonIconHoverColor: $gray-600 !default;
$toggleButtonActiveBg: $primaryColor !default;
$toggleButtonActiveBorderColor: $toggleButtonActiveBg !default;
$toggleButtonTextActiveColor: $white !default;
$toggleButtonIconActiveColor: $white !default;
$toggleButtonActiveHoverBg: $pink-600 !default;
$toggleButtonActiveHoverBorderColor: $toggleButtonActiveHoverBg !default;
$toggleButtonTextActiveHoverColor: $toggleButtonTextActiveColor !default;
$toggleButtonIconActiveHoverColor: $toggleButtonIconActiveColor !default;

//-------------Button-----------------
//button
$buttonPadding: 0.5rem 0.5rem !default;
$buttonMargin: 0 0.25rem !default;
$buttonIconOnlyWidth: 2rem !default;
$buttonIconOnlyPadding: 0.5rem 0.5rem !default;
$buttonBg: $primaryColor !default;
$buttonTextColor: $primaryTextColor !default;
$buttonBorder: 1px solid $buttonBg !default;
$buttonHoverBg: $pink-600 !default;
$buttonTextHoverColor: $buttonTextColor !default;
$buttonHoverBorderColor: $buttonHoverBg !default;
$buttonActiveBg: $pink-700 !default;
$buttonTextActiveColor: $buttonTextColor !default;
$buttonActiveBorderColor: $buttonActiveBg !default;
$raisedButtonShadow:
  0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12) !default;
$roundedButtonBorderRadius: 50% !default;

$textButtonHoverBgOpacity: 0.05 !default;
$textButtonActiveBgOpacity: 0.2 !default;
$outlinedButtonBorder: 0 none !default;
$plainButtonTextColor: $textColor !default;
$plainButtonHoverBgColor: $gray-50 !default;
$plainButtonActiveBgColor: $gray-100 !default;

$secondaryButtonBg: $gray-200 !default;
$secondaryButtonTextColor: $textColor !default;
$secondaryButtonBorder: 1px solid $secondaryButtonBg !default;
$secondaryButtonHoverBg: $gray-300 !default;
$secondaryButtonTextHoverColor: $secondaryButtonTextColor !default;
$secondaryButtonHoverBorderColor: $secondaryButtonBg !default;
$secondaryButtonActiveBg: $gray-400 !default;
$secondaryButtonTextActiveColor: $white !default;
$secondaryButtonActiveBorderColor: $secondaryButtonActiveBg !default;
$secondaryButtonFocusShadow: none !default;

$infoButtonBg: $gray-500 !default;
$infoButtonTextColor: $buttonTextColor !default;
$infoButtonBorder: 1px solid $infoButtonBg !default;
$infoButtonHoverBg: $gray-600 !default;
$infoButtonTextHoverColor: $infoButtonTextColor !default;
$infoButtonHoverBorderColor: $infoButtonHoverBg !default;
$infoButtonActiveBg: $gray-700 !default;
$infoButtonTextActiveColor: $infoButtonTextColor !default;
$infoButtonActiveBorderColor: $infoButtonActiveBg !default;
$infoButtonFocusShadow: none !default;

$successButtonBg: $teal-500 !default;
$successButtonTextColor: $buttonTextColor !default;
$successButtonBorder: 1px solid $successButtonBg !default;
$successButtonHoverBg: $teal-600 !default;
$successButtonTextHoverColor: $successButtonTextColor !default;
$successButtonHoverBorderColor: $successButtonHoverBg !default;
$successButtonActiveBg: $teal-700 !default;
$successButtonTextActiveColor: $successButtonTextColor !default;
$successButtonActiveBorderColor: $successButtonActiveBg !default;
$successButtonFocusShadow: none !default;

$warningButtonBg: $yellow-500 !default;
$warningButtonTextColor: $buttonTextColor !default;
$warningButtonBorder: 1px solid $warningButtonBg !default;
$warningButtonHoverBg: $yellow-600 !default;
$warningButtonTextHoverColor: $warningButtonTextColor !default;
$warningButtonHoverBorderColor: $warningButtonHoverBg !default;
$warningButtonActiveBg: $yellow-700 !default;
$warningButtonTextActiveColor: $warningButtonTextColor !default;
$warningButtonActiveBorderColor: $warningButtonActiveBg !default;
$warningButtonFocusShadow: none !default;

$helpButtonBg: $purple-500 !default;
$helpButtonTextColor: $buttonTextColor !default;
$helpButtonBorder: 1px solid $helpButtonBg !default;
$helpButtonHoverBg: $purple-600 !default;
$helpButtonTextHoverColor: $helpButtonTextColor !default;
$helpButtonHoverBorderColor: $purple-600 !default;
$helpButtonActiveBg: $purple-700 !default;
$helpButtonTextActiveColor: $helpButtonTextColor !default;
$helpButtonActiveBorderColor: $purple-700 !default;
$helpButtonFocusShadow: none !default;

$dangerButtonBg: $red-500 !default;
$dangerButtonTextColor: $buttonTextColor !default;
$dangerButtonBorder: 1px solid $dangerButtonBg !default;
$dangerButtonHoverBg: $red-600 !default;
$dangerButtonTextHoverColor: $dangerButtonTextColor !default;
$dangerButtonHoverBorderColor: $dangerButtonHoverBg !default;
$dangerButtonActiveBg: $red-700 !default;
$dangerButtonTextActiveColor: $dangerButtonTextColor !default;
$dangerButtonActiveBorderColor: $dangerButtonActiveBg !default;
$dangerButtonFocusShadow: none !default;

$linkButtonColor: $primaryColor !default;
$linkButtonHoverColor: $pink-600 !default;
$linkButtonTextHoverDecoration: underline !default;
$linkButtonFocusShadow: none !default;

//speeddial
$speedDialButtonWidth: 3.5rem !default;
$speedDialButtonHeight: 3.5rem !default;
$speedDialButtonIconFontSize: 1rem !default;
$speedDialActionWidth: 2.625rem !default;
$speedDialActionHeight: 2.625rem !default;
$speedDialActionBg: $purple-500 !default;
$speedDialActionHoverBg: $purple-600 !default;
$speedDialActionTextColor: $white !default;
$speedDialActionTextHoverColor: $speedDialActionTextColor !default;

//-------------Data-----------------
//table
$tableHeaderBg: $white !default;
$tableHeaderBorder: 1px solid $tableHeaderBg !default;
$tableHeaderBorderWidth: 0 0 1px 0 !default;
$tableHeaderTextColor: $gray-900 !default;
$tableHeaderFontWeight: 500 !default;
$tableHeaderPadding: 0.75rem 0 !default;

$tableHeaderCellPadding: 0.75rem 0.5rem !default;
$tableHeaderCellBg: $gray-800 !default;
$tableHeaderCellTextColor: $gray-50 !default;
$tableHeaderCellFontWeight: 500 !default;
$tableHeaderCellBorder: $divider !default;
$tableHeaderCellBorderWidth: 0 0 1px 0 !default;
$tableHeaderCellHoverBg: $tableHeaderCellBg !default;
$tableHeaderCellTextHoverColor: $tableHeaderCellTextColor !default;
$tableHeaderCellIconColor: $gray-600 !default;
$tableHeaderCellIconHoverColor: $gray-200 !default;
$tableHeaderCellHighlightBg: $pink-800 !default;
$tableHeaderCellHighlightTextColor: $pink-50 !default;
$tableHeaderCellHighlightHoverBg: $pink-700 !default;
$tableHeaderCellHighlightTextHoverColor: $tableHeaderCellHighlightTextColor !default;
$tableSortableColumnBadgeSize: 1rem !default;

$tableBodyRowBg: $white !default;
$tableBodyRowTextColor: $textColor !default;
$tableBodyRowEvenBg: $tableBodyRowBg !default;
$tableBodyRowHoverBg: $gray-50 !default;
$tableBodyRowTextHoverColor: $tableBodyRowTextColor !default;
$tableBodyCellBorder: $divider !default;
$tableBodyCellBorderWidth: 0 0 1px 0 !default;
$tableBodyCellPadding: 0.5rem 0.5rem !default;

$tableFooterCellPadding: 0.5rem 0.5rem !default;
$tableFooterCellBg: $gray-50 !default;
$tableFooterCellTextColor: $textColor !default;
$tableFooterCellFontWeight: 500 !default;
$tableFooterCellBorder: $divider !default;
$tableFooterCellBorderWidth: 0 0 1px 0 !default;
$tableResizerHelperBg: $primaryColor !default;

$tableFooterBorder: $divider !default;
$tableFooterBorderWidth: 0 0 1px 0 !default;
$tableFooterBg: $tableFooterCellBg !default;
$tableFooterTextColor: $textColor !default;
$tableFooterFontWeight: 500 !default;
$tableFooterPadding: 0.75rem 0.75rem !default;

$tableCellContentAlignment: left !default;
$tableTopPaginatorBorderWidth: 0 0 1px 0 !default;
$tableBottomPaginatorBorderWidth: 0 0 1px 0 !default;

$tableWrapperMinHeight: 25rem !default;
$tableWrapperMaxHeight: 80vh !default;

$tableScaleSM: 0.67 !default;
$tableScaleLG: 1.34 !default;

//dataview
$dataViewContentPadding: 0 !default;
$dataViewContentBorder: 0 none !default;
$dataViewListItemBorder: solid $gray-50 !default;
$dataViewListItemBorderWidth: 0 0 1px 0 !default;

//org chart
$organizationChartConnectorColor: $gray-200 !default;

//paginator
$paginatorBg: $white !default;
$paginatorTextColor: $textColor !default;
$paginatorBorder: $divider !default;
$paginatorBorderWidth: 0 !default;
$paginatorPadding: 0.5rem 1rem !default;
$paginatorElementWidth: 2.5rem !default;
$paginatorElementHeight: 2.5rem !default;
$paginatorElementBg: transparent !default;
$paginatorElementBorder: 0 none !default;
$paginatorElementIconColor: $textColor !default;
$paginatorElementHoverBg: $pink-50 !default;
$paginatorElementHoverBorderColor: transparent !default;
$paginatorElementIconHoverColor: $paginatorElementIconColor !default;
$paginatorElementBorderRadius: 0.25rem !default;
$paginatorElementMargin: 0 0.25rem !default;
$paginatorElementPadding: 0 !default;

//tree
$treeNodePadding: 0.5rem !default;
$treeNodeContentPadding: 0 !default;
$treeNodeChildrenPadding: 0 0 0 0.5rem !default;
$treeNodeIconColor: $gray-700 !default;

//timeline
$timelineVerticalEventContentPadding: 0 1rem !default;
$timelineHorizontalEventContentPadding: 1rem 0 !default;
$timelineEventMarkerWidth: 1rem !default;
$timelineEventMarkerHeight: 1rem !default;
$timelineEventMarkerBorderRadius: 50% !default;
$timelineEventMarkerBorder: 1px solid $gray-700 !default;
$timelineEventMarkerBackground: $white !default;
$timelineEventConnectorSize: 0.125rem !default;
$timelineEventColor: $gray-200 !default;

//schedule
$fullCalendarEventBg: $pink-700 !default;
$fullCalendarEventBorderColor: $fullCalendarEventBg !default;
$fullCalendarEventBorder: 1px solid fullCalendarEventBg !default;
$fullCalendarEventTextColor: $white !default;

//-------------Panel-----------------
//accordion
$accordionSpacing: 0 !default;
$accordionHeaderBorder: $divider !default;
$accordionHeaderBg: $white !default;
$accordionHeaderTextColor: $textColor !default;
$accordionHeaderFontWeight: 400 !default;
$accordionHeaderPadding: 1rem !default;

$accordionHeaderHoverBg: $gray-50 !default;
$accordionHeaderHoverBorderColor: $accordionHeaderBorder !default;
$accordionHeaderTextHoverColor: $textColor !default;

$accordionHeaderActiveBg: $accordionHeaderBg !default;
$accordionHeaderActiveBorderColor: $accordionHeaderBorder !default;
$accordionHeaderTextActiveColor: $textColor !default;

$accordionHeaderActiveHoverBg: $accordionHeaderHoverBg !default;
$accordionHeaderActiveHoverBorderColor: $accordionHeaderBorder !default;
$accordionHeaderTextActiveHoverColor: $textColor !default;

$accordionContentBorder: $accordionHeaderBorder !default;
$accordionContentBg: $accordionHeaderBg !default;
$accordionContentTextColor: $textColor !default;
$accordionContentPadding: 1rem !default;

//card
$cardBodyPadding: 1rem !default;
$cardTitleFontSize: 1.25rem !default;
$cardTitleFontWeight: 700 !default;
$cardSubTitleFontWeight: 400 !default;
$cardSubTitleColor: $gray-600 !default;
$cardContentPadding: 0.5rem 0 !default;
$cardFooterPadding: 0.5rem 0 0 0 !default;
$cardShadow:
  0 2px 1px -1px rgba(0, 0, 0, 0.2),
  0 1px 1px 0 rgba(0, 0, 0, 0.14),
  0 1px 3px 0 rgba(0, 0, 0, 0.12) !default;

//panel
$panelHeaderBorderColor: $dividerColor !default;
$panelHeaderBorder: $divider !default;
$panelHeaderBg: $white !default;
$panelHeaderTextColor: $textColor !default;
$panelHeaderFontWeight: 500 !default;
$panelHeaderPadding: 1rem !default;
$panelToggleableHeaderPadding: 0.5rem 1rem !default;

$panelHeaderHoverBg: $gray-50 !default;
$panelHeaderHoverBorderColor: $panelHeaderBorderColor !default;
$panelHeaderTextHoverColor: $panelHeaderTextColor !default;

$panelContentBorderColor: $dividerColor !default;
$panelContentBorder: $divider !default;
$panelContentBg: $white !default;
$panelContentEvenRowBg: $white !default;
$panelContentTextColor: $textColor !default;
$panelContentPadding: 1rem !default;

$panelFooterBorder: $divider !default;
$panelFooterBg: $white !default;
$panelFooterTextColor: $textColor !default;
$panelFooterPadding: 1rem !default;

//splitter
$splitterGutterBg: $gray-50 !default;
$splitterGutterHandleBg: $gray-200 !default;

//scrollpanel
$scrollPanelTrackBorder: 0 none !default;
$scrollPanelTrackBg: $gray-200 !default;

//tabview
$tabviewNavBorder: $divider !default;
$tabviewNavBorderWidth: 0 0 2px 0 !default;
$tabviewNavBg: $white !default;

$tabviewHeaderSpacing: 0 !default;
$tabviewHeaderBorder: $tabviewNavBorder !default;
$tabviewHeaderBorderWidth: 0 0 2px 0 !default;
$tabviewHeaderBorderColor: $gray-200 !default;
$tabviewHeaderBg: $tabviewNavBg !default;
$tabviewHeaderTextColor: $gray-700 !default;
$tabviewHeaderFontWeight: 600 !default;
$tabviewHeaderPadding: 0.5rem 1rem !default;
$tabviewHeaderMargin: 0 0 -2px 0 !default;

$tabviewHeaderHoverBg: $gray-100 !default;
$tabviewHeaderHoverBorderColor: $gray-700 !default;
$tabviewHeaderTextHoverColor: $tabviewHeaderTextColor !default;

$tabviewHeaderActiveBg: $white !default;
$tabviewHeaderActiveBorderColor: $pink-500 !default;
$tabviewHeaderTextActiveColor: $primaryColor !default;

$tabviewContentBorder: 0 none !default;
$tabviewContentBg: $white !default;
$tabviewContentTextColor: $textColor !default;
$tabviewContentPadding: $panelContentPadding !default;
$tabviewContentFontSize: 1.125rem !default;

//-------------Overlay-----------------
//dialog
$dialogHeaderBg: $white !default;
$dialogHeaderBorder: 1px solid var(--gray-300) !default;
$dialogHeaderTextColor: $textColor !default;
$dialogHeaderFontWeight: 700 !default;
$dialogHeaderFontSize: 1.25rem !default;
$dialogHeaderPadding: 1rem !default;
$dialogContentPadding: 1rem !default;
$dialogFooterBorder: 0 none !default;
$dialogFooterPadding: 1rem !default;

//overlays
$overlayContentBorder: 0 none !default;
$overlayContentBg: $white !default;
$overlayContainerShadow:
  0 11px 15px -7px rgba(0, 0, 0, 0.2),
  0 24px 38px 3px rgba(0, 0, 0, 0.14),
  0 9px 46px 8px rgba(0, 0, 0, 0.12) !default;

//confirmpopup
$confirmPopupContentPadding: 1rem !default;
$confirmPopupFooterPadding: 1rem !default;

//tooltip
$tooltipBg: $purple-100 !default;
$tooltipTextColor: $purple-600 !default;
$tooltipPadding: 0.5rem 0.75rem !default;

//-------------File-----------------
//upload
$fileUploadProgressBarHeight: 4px !default;
$fileUploadContentPadding: 2rem 1rem !default;

//-------------Menu-----------------
//dock
$dockActionWidth: 3.5rem !default;
$dockActionHeight: 3.5rem !default;
$dockItemPadding: 0.5rem !default;
$dockItemBorderRadius: $borderRadius !default;
$dockCurrentItemMargin: 1.5rem !default;
$dockFirstItemsMargin: 1.3rem !default;
$dockSecondItemsMargin: 0.9rem !default;
$dockBg: rgba($white, 0.2) !default;
$dockBorder: 1px solid rgba($white, 0.3) !default;
$dockPadding: 0.5rem !default;
$dockBorderRadius: 0.5rem !default;

//menu (e.g. menu, menubar, tieredmenu)
$menuWidth: 12.5rem !default;
$menuBg: $white !default;
$menuBorder: $divider !default;
$menuTextColor: $textColor !default;
$menuitemPadding: 0.75rem 1rem !default;
$menuitemBorderRadius: 0 !default;
$menuitemTextColor: $textColor !default;
$menuitemIconColor: $gray-700 !default;
$menuitemTextHoverColor: $gray-700 !default;
$menuitemIconHoverColor: $menuitemIconColor !default;
$menuitemHoverBg: $gray-100 !default;
$menuitemTextFocusColor: $textColor !default;
$menuitemIconFocusColor: $menuitemIconColor !default;
$menuitemFocusBg: $menuBg !default;
$menuitemTextActiveColor: $primaryColor !default;
$menuitemIconActiveColor: $primaryColor !default;
$menuitemActiveBg: $menuBg !default;
$menuitemActiveFocusBg: $menuBg !default;
$menuitemSubmenuIconFontSize: 1rem !default;
$submenuHeaderMargin: 0 !default;
$submenuHeaderPadding: 0.75rem 1rem !default;
$submenuHeaderBg: $white !default;
$submenuHeaderTextColor: $textColor !default;
$submenuHeaderBorderRadius: 0 !default;
$submenuHeaderFontWeight: 400 !default;
$overlayMenuBg: $menuBg !default;
$overlayMenuBorder: 0 none !default;
$overlayMenuShadow:
  0 2px 4px -1px rgba(0, 0, 0, 0.2),
  0 4px 5px 0 rgba(0, 0, 0, 0.14),
  0 1px 10px 0 rgba(0, 0, 0, 0.12) !default;
$verticalMenuPadding: 0.25rem 0 !default;
$verticalMenuitemMargin: 0 !default;
$menuSeparatorMargin: 0.25rem 0 !default;

$horizontalMenuPadding: 0.5rem !default;
$horizontalMenuBg: $white !default;
$horizontalMenuBorder: $divider !default;
$horizontalMenuTextColor: $textColor !default;
$horizontalMenuRootMenuitemPadding: 0.75rem 1rem !default;
$horizontalMenuRootMenuitemBorderRadius: $borderRadius !default;
$horizontalMenuRootMenuitemTextColor: $textColor !default;
$horizontalMenuRootMenuitemIconColor: $gray-700 !default;
$horizontalMenuRootMenuitemTextHoverColor: $gray-700 !default;
$horizontalMenuRootMenuitemIconHoverColor: $gray-700 !default;
$horizontalMenuRootMenuitemHoverBg: $gray-100 !default;
$horizontalMenuRootMenuitemTextActiveColor: $primaryColor !default;
$horizontalMenuRootMenuitemIconActiveColor: $primaryColor !default;
$horizontalMenuRootMenuitemActiveBg: $white !default;

//breadcrumb
$breadcrumbPadding: 1rem !default;
$breadcrumbBg: $gray-50 !default;
$breadcrumbBorder: none;
$breadcrumbItemTextColor: $textColor !default;
$breadcrumbItemIconColor: $gray-700 !default;
$breadcrumbLastItemTextColor: $primaryColor !default;
$breadcrumbLastItemIconColor: $primaryColor !default;
$breadcrumbSeparatorColor: $gray-700 !default;

//steps
$stepsItemBg: $white !default;
$stepsItemBorder: $divider !default;
$stepsItemTextColor: $textColor !default;
$stepsItemNumberWidth: 1.75rem !default;
$stepsItemNumberHeight: 1.75rem !default;
$stepsItemNumberFontSize: 1rem !default;
$stepsItemNumberColor: $textColor !default;
$stepsItemNumberBorderRadius: 50% !default;
$stepsItemActiveFontWeight: 500 !default;

//-------------Messages-----------------
//message
$messageMargin: 0.5rem 0 !default;
$messagePadding: 1rem 1.5rem !default;
$messageBorderWidth: 0 0 0 0.25rem !default;
$messageIconFontSize: 1.25rem !default;
$messageTextFontSize: 1rem !default;
$messageTextFontWeight: 500 !default;

//inline message
$inlineMessagePadding: 0.75rem !default;
$inlineMessageMargin: 0 !default;
$inlineMessageIconFontSize: 1rem !default;
$inlineMessageTextFontSize: 1rem !default;
$inlineMessageBorderWidth: 0 !default;

//severities
$infoMessageBg: $gray-100 !default;
$infoMessageBorder: solid $gray-500 !default;
$infoMessageTextColor: $gray-700 !default;
$infoMessageIconColor: $gray-500 !default;
$successMessageBg: $teal-50 !default;
$successMessageBorder: solid $teal-500 !default;
$successMessageTextColor: $teal-700 !default;
$successMessageIconColor: $teal-500 !default;
$warningMessageBg: $yellow-50 !default;
$warningMessageBorder: solid $yellow-500 !default;
$warningMessageTextColor: $yellow-700 !default;
$warningMessageIconColor: $yellow-500 !default;
$errorMessageBg: $red-50 !default;
$errorMessageBorder: solid $red-500 !default;
$errorMessageTextColor: $red-600 !default;
$errorMessageIconColor: $red-500 !default;

//toast
$toastIconFontSize: 1.25rem !default;
$toastMessageTextMargin: 0 0 0 1rem !default;
$toastMargin: 0 0 0.5rem 0 !default;
$toastPadding: 1rem !default;
$toastBorderWidth: 0 0 0 0.25rem !default;
$toastShadow:
  0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12) !default;
$toastOpacity: 0.9 !default;
$toastTitleFontWeight: 700 !default;
$toastDetailMargin: 0.5rem 0 0 0 !default;

//-------------Media-----------------
//carousel
$carouselIndicatorsPadding: 1rem !default;
$carouselIndicatorBg: $gray-200 !default;
$carouselIndicatorHoverBg: $gray-400 !default;
$carouselIndicatorBorderRadius: 0 !default;
$carouselIndicatorWidth: 1.75rem !default;
$carouselIndicatorHeight: 0.5rem !default;

//galleria
$galleriaIndicatorsPadding: 0.75rem !default;
$galleriaIndicatorBg: $gray-300 !default;
$galleriaIndicatorHoverBg: $gray-500 !default;
$galleriaIndicatorBorderRadius: 50% !default;
$galleriaIndicatorWidth: 1rem !default;
$galleriaIndicatorHeight: 1rem !default;
$galleriaIndicatorHoverBgOnItem: rgba($white, 0.4) !default;
$galleriaIndicatorBgOnItem: rgba($white, 0.6) !default;
$galleriaIndicatorsBgOnItem: rgba($gray-900, 0.8) !default;

$galleriaThumbnailContainerBg: rgba($gray-900, 0.9) !default;
$galleriaThumbnailContainerPadding: 0.75rem 0.25rem !default;
$galleriaThumbnailNavigatorBg: transparent !default;
$galleriaThumbnailNavigatorColor: $gray-500 !default;
$galleriaThumbnailNavigatorHoverBg: rgba($white, 0.2) !default;
$galleriaThumbnailNavigatorHoverColor: $gray-500 !default;
$galleriaThumbnailNavigatorBorderRadius: 50% !default;
$galleriaThumbnailNavigatorWidth: 1.75rem !default;
$galleriaThumbnailNavigatorHeight: 1.75rem !default;

$galleriaCloseIconMargin: 0.5rem !default;
$galleriaCloseIconFontSize: 1.75rem !default;
$galleriaCloseIconBg: transparent !default;
$galleriaCloseIconColor: $gray-50 !default;
$galleriaCloseIconHoverBg: rgba($white, 0.2) !default;
$galleriaCloseIconHoverColor: $gray-50 !default;
$galleriaCloseIconWidth: 1.75rem !default;
$galleriaCloseIconHeight: 1.75rem !default;
$galleriaCloseIconBorderRadius: 50% !default;

$galleriaItemNavigatorBg: transparent !default;
$galleriaItemNavigatorColor: $gray-50 !default;
$galleriaItemNavigatorMargin: 0 0.5rem !default;
$galleriaItemNavigatorFontSize: 1.75rem !default;
$galleriaItemNavigatorHoverBg: rgba($white, 0.2) !default;
$galleriaItemNavigatorHoverColor: $gray-50 !default;
$galleriaItemNavigatorWidth: 3.5rem !default;
$galleriaItemNavigatorHeight: 3.5rem !default;
$galleriaItemNavigatorBorderRadius: 50% !default;

$galleriaCaptionBg: rgba($gray-800, 0.8) !default;
$galleriaCaptionTextColor: $gray-50 !default;
$galleriaCaptionPadding: 1rem !default;

$galleriaMaskBg: $gray-900 !default;

//image
$imageMaskBg: $gray-900 !default;
$imagePreviewToolbarPadding: 1rem !default;
$imagePreviewIndicatorColor: $gray-50 !default;
$imagePreviewIndicatorBg: transparentize($gray-900, 0.6) !default;
$imagePreviewActionIconBg: transparent !default;
$imagePreviewActionIconColor: $imagePreviewIndicatorColor !default;
$imagePreviewActionIconHoverBg: transparentize($white, 0.1) !default;
$imagePreviewActionIconHoverColor: $imagePreviewIndicatorColor !default;
$imagePreviewActionIconWidth: 2.5rem !default;
$imagePreviewActionIconHeight: 2.5rem !default;
$imagePreviewActionIconFontSize: 1.25rem !default;
$imagePreviewActionIconBorderRadius: 50% !default;

//-------------Misc-----------------
//avatar
$avatarBg: $gray-50 !default;
$avatarTextColor: $textColor !default;

//badge
$badgeBg: $white !default;
$badgeTextColor: $primaryTextColor !default;
$badgeMinWidth: 1.5rem !default;
$badgeHeight: 1.5rem !default;
$badgeFontWeight: 700 !default;
$badgeFontSize: 0.75rem !default;
// tag
$tagBg: $primaryColor !default;
$tagTextColor: $primaryTextColor !default;
$tagMinWidth: 1.5rem !default;
$tagHeight: 1.5rem !default;
$tagFontWeight: 400 !default;
$tagFontSize: 1rem !default;

$tagPadding: 0.25rem 0.5rem !default;

//chip
$chipBg: $gray-100 !default;
$chipTextColor: $textColor !default;
$chipBorderRadius: 0.5rem !default;

//inplace
$inplacePadding: $inputPadding !default;
$inplaceHoverBg: $gray-100 !default;
$inplaceTextHoverColor: $textColor !default;

//scrollTop
$scrollTopBg: rgba($gray-800, 0.8) !default;
$scrollTopHoverBg: rgba($gray-900, 0.8) !default;
$scrollTopWidth: 3.5rem !default;
$scrollTopHeight: 3.5rem !default;
$scrollTopBorderRadius: 50% !default;
$scrollTopFontSize: 1rem !default;
$scrollTopTextColor: $white !default;

//skeleton
$skeletonBg: $gray-100 !default;
$skeletonAnimationBg: $gray-50 !default;

//progressbar
$progressBarHeight: 1.25rem !default;
$progressBarBorder: 0 none !default;
$progressBarBg: $gray-100 !default;
$progressBarValueBg: $pink-600 !default;
$progressBarValueTextColor: $white !default;

:root {
  // --surface-a: #ffffff;
  // --surface-b: #fafafa;
  // --surface-c: rgba(0, 0, 0, 0.04);
  // --surface-d: rgba(0, 0, 0, 0.12);
  // --surface-e: #ffffff;
  // --surface-f: #ffffff;
  --text-color: #{$textColor};
  --text-color-secondary: #{$textSecondaryColor};
  --primary-color: #{$primaryColor};
  --primary-color-text: #{$primaryTextColor};
  --font-family: #{$fontFamily};
  --surface-0: #{$white};
  --surface-50: #{$gray-50};
  --surface-100: #{$gray-100};
  --surface-200: #{$gray-200};
  --surface-400: #{$gray-300};
  --surface-500: #{$gray-400};
  --surface-600: #{$gray-500};
  --surface-700: #{$gray-600};
  --surface-800: #{$gray-700};
  --surface-900: #{$gray-800};
  --gray-50: #f6f5f8;
  --gray-100: #eceaec;
  --gray-200: #dedade;
  --gray-300: #bcb5bc;
  --gray-400: #a59fa5;
  --gray-500: #867986;
  --gray-600: #776c77;
  --gray-700: #595559;
  --gray-800: #333333;
  --gray-900: #202124;
  --blue-50: #d3dffb;
  --blue-100: #a7bff7;
  --blue-200: #7b9ff3;
  --blue-300: #4f7fef;
  --blue-400: #245fea;
  --blue-500: #134acb;
  --blue-600: #103ea9;
  --blue-700: #0d3187;
  --blue-800: #092566;
  --blue-900: #061944;
  --green-50: #d6f8e6;
  --green-100: #acf2cc;
  --green-200: #83ebb3;
  --green-300: #5ae49a;
  --green-400: #31dd81;
  --green-500: #1fbf69;
  --green-600: #199954;
  --green-700: #13733f;
  --green-800: #0c4c2a;
  --green-900: #062615;
  --yellow-50: #fef4d5;
  --yellow-100: #fce8ab;
  --yellow-200: #fbdd81;
  --yellow-300: #f9d157;
  --yellow-400: #f8c62d;
  --yellow-500: #f2b808;
  --yellow-600: #ca9907;
  --yellow-700: #a17b05;
  --yellow-800: #795c04;
  --yellow-900: #513d03;
  --cyan-50: #cff1fb;
  --cyan-100: #9fe4f8;
  --cyan-200: #6fd6f4;
  --cyan-300: #3fc9f1;
  --cyan-400: #12baeb;
  --cyan-500: #0e94bb;
  --cyan-600: #0c7b9c;
  --cyan-700: #09637d;
  --cyan-800: #074a5e;
  --cyan-900: #05313e;
  --pink-50: #ffebf5;
  --pink-100: #f9d2e4;
  --pink-200: #f4a4c9;
  --pink-300: #ee77af;
  --pink-400: #e94994;
  --pink-500: #e31c79;
  --pink-600: #b61661;
  --pink-700: #a40d49;
  --pink-800: #881149;
  --pink-900: #5b0b30;
  --indigo-50: #ded8f9;
  --indigo-100: #bdb1f2;
  --indigo-200: #9b8aec;
  --indigo-300: #7a62e6;
  --indigo-400: #593bdf;
  --indigo-500: #4021cc;
  --indigo-600: #371caf;
  --indigo-700: #2e1892;
  --indigo-800: #251375;
  --indigo-900: #1b0e57;
  --teal-50: #d4f8f5;
  --teal-100: #a9f1ec;
  --teal-200: #7eebe2;
  --teal-300: #53e4d9;
  --teal-400: #28ddcf;
  --teal-500: #00b0a3;
  --teal-600: #009388;
  --teal-700: #00756d;
  --teal-800: #005852;
  --teal-900: #003b36;
  --orange-50: #ffe8de;
  --orange-100: #ffd4c2;
  --orange-200: #ffaa85;
  --orange-300: #fe8049;
  --orange-400: #fe550c;
  --orange-500: #e84701;
  --orange-600: #c73d01;
  --orange-700: #a63201;
  --orange-800: #842801;
  --orange-900: #631e00;
  --bluegray-50: #f7f9f9;
  --bluegray-100: #d9e0e3;
  --bluegray-200: #bbc7cd;
  --bluegray-300: #9caeb7;
  --bluegray-400: #7e96a1;
  --bluegray-500: #607d8b;
  --bluegray-600: #526a76;
  --bluegray-700: #435861;
  --bluegray-800: #35454c;
  --bluegray-900: #263238;
  --purple-50: #f3edf9;
  --purple-100: #e3d4ef;
  --purple-200: #c8a8e0;
  --purple-300: #ac7dd0;
  --purple-400: #9151c1;
  --purple-500: #72399e;
  --purple-600: #532973;
  --purple-700: #371b4d;
  --purple-800: #29153a;
  --purple-900: #1c0e26;
  --red-50: #ffecee;
  --red-100: #fcc9ce;
  --red-200: #fa929e;
  --red-300: #f75c6d;
  --red-400: #f5263d;
  --red-500: #d80a21;
  --red-600: #b4081b;
  --red-700: #900716;
  --red-800: #6c0510;
  --red-900: #48030b;
  --primary-50: #f5f6fb;
  --primary-100: #d1d5ed;
  --primary-200: #acb4df;
  --primary-300: #8893d1;
  --primary-400: #6372c3;
  --primary-500: #3f51b5;
  --primary-600: #36459a;
  --primary-700: #2c397f;
  --primary-800: #232d64;
  --primary-900: #192048;
  --content-padding: #{$panelContentPadding};
  --inline-spacing: #{$inlineSpacing};
  --border-radius: #{$borderRadius};
  --surface-ground: $gray-50;
  --surface-section: $white;
  --surface-card: $white;
  --surface-overlay: $white;
  --surface-border: $gray-200;
  --surface-hover: $gray-200;
  --maskbg: #{$maskBg};
  --highlight-bg: #{$highlightBg};
  --highlight-text-color: #{$highlightTextColor};
  --focus-ring: #{$focusShadow};
  color-scheme: light;
}

// Custom
// Chips
$chipNewBackgroundColor: #ffebf5;
$chipNewColor: #b61661;
$chipClosedBackgroundColor: #776c77;
$chipClosedColor: map-get($colors, 'white');
$chipInProgressBackgroundColor: #d3dffb;
$chipInProgressColor: #092566;
$chipAwaitingInfoBackgroundColor: #e3d4ef;
$chipAwaitingInfoColor: #72399e;
$chipResolvedInfoBackgroundColor: #d4f8f5;
$chipResolvedColor: #00756d;
$chipApprovalBackgroundColor: #fce8ab;
$chipApprovalColor: #795c04;
$chipImplementationBackgroundColor: #d3dffb;
$chipImplementationColor: #092566;
$chipOnHoldBackgroundColor: #e3d4ef;
$chipOnHoldColor: #72399e;
$chipPIRBackgroundColor: #d4f8f5;
$chipPIRColor: #00765d;
$chipOpenColor: #795c04;
$chipOpenBackgroundColor: #fce8ab;
$chipClosedCompleteBackgroundColor: #776c77;
$chipClosedCompleteColor: map-get($colors, 'white');
