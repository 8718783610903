p-autocomplete.ng-touched.ng-invalid {
  .p-autocomplete > .p-inputtext {
    @include invalid-filled-input();
  }

  .p-autocomplete .p-autocomplete-multiple-container {
    @include invalid-filled-input-wrapper();
  }
}

p-autocomplete.ng-touched.ng-invalid {
  .p-autocomplete > .p-inputtext {
    &:enabled:focus {
      box-shadow:
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor;
    }
  }

  .p-autocomplete .p-autocomplete-multiple-container {
    &:not(.p-disabled).p-focus {
      box-shadow:
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor;
    }
  }
}

p-autocomplete.ng-touched.ng-invalid > .p-autocomplete > .p-inputtext {
  @include invalid-input();
}
