.p-multiselect {
  &.p-multiselect-label-hide {
    .p-multiselect-label {
      display: none;
    }
    .p-multiselect-filter-container {
      .p-inputtext {
        min-width: 10rem;
      }
    }
  }
  &.p-multiselect-border {
    &:has(> .p-element > .p-overlay),
    &:hover {
      border: 1px solid map-get($colors, 'pink');
      .p-multiselect-trigger-icon {
        color: map-get($colors, 'pink');
      }
    }
  }
}

p-multiselect.ng-touched.ng-invalid {
  .p-multiselect {
    &:not(.p-disabled).p-focus {
      box-shadow:
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor;
    }
  }
}

p-multiselect.ng-touched.ng-invalid > .p-multiselect {
  @include invalid-input();
}
