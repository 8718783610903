.p-orderlist {
  .p-orderlist-list {
    .p-orderlist-item {
      &:focus {
        background: $emphasis-lower;

        &.p-highlight {
          background: rgba($primaryColor, 0.24);
        }
      }
    }
  }
}
