.p-datatable-equal-width .p-datatable .p-datatable-thead > tr > th,
.p-datatable-equal-width .p-datatable .p-datatable-tbody > tr > td {
  flex: 1 1;
  overflow: hidden;
}

.p-datatable-equal-width .p-datatable .p-datatable-thead > tr,
.p-datatable-equal-width .p-datatable .p-datatable-tbody > tr {
  display: flex;
  width: 100%;
}

.p-datatable-equal-width .p-datatable .p-datatable-thead,
.p-datatable-equal-width .p-datatable .p-datatable-tbody {
  display: block;
}
