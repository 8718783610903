.main-nav {
  transition: width 0.5s ease;
  background-color: $gray-900;
  width: 0;
  box-sizing: border-box;
  @media screen and (max-width: $calendarBreakpoint) {
    top: 4rem;
    left: 0;
    position: fixed;
    height: calc(100vh - 4rem) !important;
  }
  &.show-main-nav {
    width: 15rem;
    @media screen and (max-width: $calendarBreakpoint) {
      width: 100%;
    }
  }
  .p-menu {
    background-color: $gray-900;
    width: 15rem;
    .p-menu-list {
      .p-submenu-header {
        background-color: transparent !important;
        color: map-get($colors, 'primary') !important;
        font-weight: bold;
        padding: 0;
        span {
          display: block;
          padding: 0.25rem 1rem;
        }
        a {
          color: map-get($colors, 'white') !important;
          font-weight: normal;
          text-decoration: none;
          display: block;
          padding: 0.75rem 1rem;
        }
        &:has(a) {
          &:hover {
            background-color: #eceaec !important;
            a {
              color: #595559 !important;
            }
          }
        }
      }
      .p-menuitem-link {
        padding-left: 2.5rem !important;
      }
      .p-menuitem-text {
        color: map-get($colors, 'white') !important;
      }
    }
  }
  .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-text {
    color: #595559 !important;
  }
}

.bg-impersonate {
  background-color: #532973 !important;
}

.notifications-overlay-panel {
  .p-overlaypanel-content {
    padding: 0;
  }
}

.help-icon:hover {
  border: 1px solid #ae165b !important;
  border-radius: 50% !important;
  color: #ae165b !important;
  background-color: #fe418d !important;
}

.help-icon {
  border: 1px solid transparent !important;
  border-radius: 50% !important;
}

.prime-help-icon:hover {
  color: #ae165b;
}

.avatar-hover:hover {
  border: 1px solid $pink-500 !important;
  border-radius: 50% !important;
}

.avatar-hover {
  border: 1px solid transparent !important;
}

.avatar-hover:focus {
  border: 1px solid $pink-500 !important;
}
