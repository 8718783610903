.p-selectbutton {
  .p-button {
    margin: 0;
    border-radius: 0;
    &:first-child {
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
    }

    &:last-child {
      border-top-right-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
  }
}

p-selectbutton.ng-touched.ng-invalid > .p-selectbutton > .p-button {
  @include invalid-input();
}
