p-dropdown.ng-touched.ng-invalid {
  .p-dropdown {
    &:not(.p-disabled).p-focus {
      box-shadow:
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor;
    }
  }
}

p-dropdown.ng-touched.ng-invalid > .p-dropdown {
  @include invalid-input();
}
