.p-inputtext {
  font-family: $fontFamily;
  font-size: $inputTextFontSize;
  color: $inputTextColor;
  background: $inputBg;
  padding: $inputPadding;
  border: $inputBorder;
  transition: $formElementTransition;
  appearance: none;
  border-radius: $borderRadius;

  &:enabled:hover {
    border-color: $inputHoverBorderColor;
  }

  &:enabled:focus {
    @include focused-input();
  }

  &.ng-touched.ng-invalid {
    @include invalid-input();
  }

  &.p-inputtext-sm {
    @include scaledFontSize($inputTextFontSize, $scaleSM);
    @include scaledPadding($inputPadding, $scaleSM);
  }

  &.p-inputtext-lg {
    @include scaledFontSize($inputTextFontSize, $scaleLG);
    @include scaledPadding($inputPadding, $scaleLG);
  }
}
