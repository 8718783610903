.p-chip-primary {
  background-color: $primaryColor;
  color: $primaryTextColor;
}

.p-chip-success {
  background-color: $successMessageBg;
  color: $successMessageTextColor;
}

.p-chip-warning {
  background-color: $warningMessageBg;
  color: $warningMessageTextColor;
}

.p-chip-danger {
  background-color: $errorMessageBg;
  color: $errorMessageTextColor;
}

.p-chip-dark {
  background-color: map-get($colors, 'black');
  color: map-get($colors, 'white');
}

.p-chip-Comment {
  background-color: $teal-300;
}

.p-chip-Expedite {
  color: #72399e;
  background-color: #e3d4ef;
}

.p-chip-Escalate {
  color: #72399e;
  background-color: #e3d4ef;
}

// Table Chips

// .p-chip-pending {
//   background-color: #a40d49;
//   color: $primaryTextColor;
// }

.p-chip-open {
  background-color: #fe418d;
  color: $primaryTextColor;
}

.p-chip-cancelled {
  background-color: $gray-300;
  color: #333333;
}

// .p-chip-closed {
//   background-color: $gray-800;
//   color: $primaryTextColor;
// }

// .p-chip-open,
.p-chip-true,
.p-chip-fulfilled {
  background-color: $teal-300;
}

.p-chip-pending {
  background-color: $orange-300;
}

.p-chip-workinprogress {
  background-color: #fe418d;
  color: $primaryTextColor;
}

// New Chips
.p-chip-new {
  color: $chipNewColor;
  background-color: $chipNewBackgroundColor;
}
.p-chip-workinprogress,
.p-chip-inprogress {
  color: $chipInProgressColor;
  background-color: $chipInProgressBackgroundColor;
}

.p-chip-awaitinginfo {
  color: $chipAwaitingInfoColor;
  background-color: $chipAwaitingInfoBackgroundColor;
}

.p-chip-resolved {
  color: $chipResolvedColor;
  background-color: $chipResolvedInfoBackgroundColor;
}

.p-chip-closed {
  color: $chipClosedColor;
  background-color: $chipClosedBackgroundColor;
}

.p-chip-open {
  color: $chipOpenColor;
  background-color: $chipOpenBackgroundColor;
}

.p-chip-approval {
  background-color: $chipApprovalBackgroundColor;
  color: $chipApprovalColor;
}

.p-chip-implementation {
  background-color: $chipImplementationBackgroundColor;
  color: $chipImplementationColor;
}

.p-chip-onhold {
  background-color: $chipOnHoldBackgroundColor;
  color: $chipOnHoldColor;
}

.p-chip-pir {
  background-color: $chipPIRBackgroundColor;
  color: $chipPIRColor;
}

.p-chip-closed-complete {
  background-color: $chipClosedCompleteBackgroundColor;
  color: $chipClosedCompleteColor;
}

.p-chip-gold {
  background-color: $chipApprovalBackgroundColor;
  color: $chipApprovalColor;
  &.p-chip-small {
    border-radius: 0.25rem;
    padding: 0.125rem 0.625rem;
    font-size: 1rem;
    .p-chip-text {
      line-height: 1;
      margin-top: 0.125rem;
      margin-bottom: 0.125rem;
    }
  }
}

p-chips.ng-touched.ng-invalid {
  .p-chips .p-chips-multiple-container {
    &:not(.p-disabled).p-focus {
      box-shadow:
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor;
    }
  }
}

p-chips.ng-touched.ng-invalid > .p-chips > .p-inputtext {
  @include invalid-input();
}
