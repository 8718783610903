.min-h-auto {
  min-height: auto;
}

@for $i from 1 through 60 {
  .min-h-#{$i}rem {
    min-height: #{$i}rem;
  }
}

@for $i from 31 through 60 {
  .h-#{$i}rem {
    height: #{$i}rem;
  }
}
