p-cascadeselect.ng-touched.ng-invalid {
  .p-cascadeselect {
    &:not(.p-disabled).p-focus {
      box-shadow:
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor;
    }
  }
}

p-cascadeselect.ng-touched.ng-invalid > .p-cascadeselect {
  @include invalid-input();
}
