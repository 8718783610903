$white: #ffffff;
$gray-50: #f6f5f8;
$gray-100: #eceaec;
$gray-200: #dedade;
$gray-300: #bcb5bc;
$gray-400: #a59fa5;
$gray-500: #867986;
$gray-600: #776c77;
$gray-700: #595559;
$gray-800: #333333;
$gray-900: #202124;
$red-50: #ffecee;
$red-100: #fcc9ce;
$red-200: #fa929e;
$red-300: #f75c6d;
$red-400: #f5263d;
$red-500: #d80a21;
$red-600: #b4081b;
$red-700: #900716;
$red-800: #6c0510;
$red-900: #48030b;
$purple-50: #f3edf9;
$purple-100: #e3d4ef;
$purple-200: #c8a8e0;
$purple-300: #ac7dd0;
$purple-400: #9151c1;
$purple-500: #72399e;
$purple-600: #532973;
$purple-700: #371b4d;
$purple-800: #29153a;
$purple-900: #1c0e26;
$orange-50: #ffe8de;
$orange-100: #ffd4c2;
$orange-200: #ffaa85;
$orange-300: #fe8049;
$orange-400: #fe550c;
$orange-500: #e84701;
$orange-600: #c73d01;
$orange-700: #a63201;
$orange-800: #842801;
$orange-900: #631e00;
$teal-50: #d4f8f5;
$teal-100: #a9f1ec;
$teal-200: #7eebe2;
$teal-300: #53e4d9;
$teal-400: #28ddcf;
$teal-500: #00b0a3;
$teal-600: #009388;
$teal-700: #00756d;
$teal-800: #005852;
$teal-900: #003b36;
$indigo-50: #ded8f9;
$indigo-100: #bdb1f2;
$indigo-200: #9b8aec;
$indigo-300: #7a62e6;
$indigo-400: #593bdf;
$indigo-500: #4021cc;
$indigo-600: #371caf;
$indigo-700: #2e1892;
$indigo-800: #251375;
$indigo-900: #1b0e57;
$pink-50: #ffebf5;
$pink-100: #f9d2e4;
$pink-200: #f4a4c9;
$pink-300: #ee77af;
$pink-400: #e94994;
$pink-500: #e31c79;
$pink-600: #b61661;
$pink-700: #a40d49;
$pink-800: #881149;
$pink-900: #5b0b30;
$yellow-50: #fef4d5;
$yellow-100: #fce8ab;
$yellow-200: #fbdd81;
$yellow-300: #f9d157;
$yellow-400: #f8c62d;
$yellow-500: #f2b808;
$yellow-600: #ca9907;
$yellow-700: #a17b05;
$yellow-800: #795c04;
$yellow-900: #513d03;
$green-50: #d6f8e6;
$green-100: #acf2cc;
$green-200: #83ebb3;
$green-300: #5ae49a;
$green-400: #31dd81;
$green-500: #1fbf69;
$green-600: #199954;
$green-700: #13733f;
$green-800: #0c4c2a;
$green-900: #062615;
$blue-50: #d3dffb;
$blue-100: #a7bff7;
$blue-200: #7b9ff3;
$blue-300: #4f7fef;
$blue-400: #245fea;
$blue-500: #134acb;
$blue-600: #103ea9;
$blue-700: #0d3187;
$blue-800: #092566;
$blue-900: #061944;

// :root {
// @if variable-exists(colors) {
//   @each $name, $color in $colors {
//       @for $i from 0 through 5 {
//           @if ($i == 0) {
//               --#{$name}-50:#{tint($color, (5 - $i) * 19%)};
//           }
//           @else {
//               --#{$name}-#{$i * 100}:#{tint($color, (5 - $i) * 19%)};
//           }
//       }

//       @for $i from 1 through 4 {
//           --#{$name}-#{($i + 5) * 100}:#{shade($color, $i * 15%)};
//       }
//   }
// }
// }
