.p-dialog-custom .p-dialog-content:last-of-type {
  padding: 0px;
}

.p-dialog-custom .p-dialog-content {
  border-radius: 0.5rem;
}

.p-dialog-footer-border {
  .p-dialog-footer {
    border-top: 1px solid var(--gray-300);
  }
}