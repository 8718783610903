.p-listbox {
  .p-listbox-list {
    .p-listbox-item {
      .p-ink {
        background-color: rgba($primaryColor, 0.16);
      }

      &:focus {
        background: $emphasis-lower;

        &.p-highlight {
          background: rgba($primaryColor, 0.24);
        }
      }
    }
  }
}
