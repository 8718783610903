.tabs-evenly-spaced {
  .p-tabview-panels{
    padding: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .p-tabview-nav-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .p-tabview-nav {
    display: flex;
    justify-content: space-between;

    li .p-tabview-nav-link {
      flex: 1;
      text-align: center;
      padding: 1rem;
    }
  }
}
