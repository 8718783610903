.p-datepicker {
  .p-timepicker {
    padding: 0.8rem 0.5rem;
  }

  .p-datepicker-header {
    .p-datepicker-title {
      margin: 0 auto 0;
      order: 2;
    }

    .p-datepicker-prev {
      order: 1;
    }

    .p-datepicker-next {
      order: 3;
    }
  }
}

p-calendar.ng-touched.ng-invalid {
  .p-inputtext {
    &:enabled:focus {
      box-shadow:
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor;
    }
  }

  .p-calendar-w-btn {
    border-color: $inputErrorBorderColor;

    .p-inputtext {
      &:enabled:focus {
        box-shadow: none;
      }
    }

    &:not(.p-disabled).p-focus {
      box-shadow:
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor,
        inset 0 0 0 1px $inputErrorBorderColor;
    }
  }
}

p-calendar.ng-touched.ng-invalid > .p-calendar > .p-inputtext {
  @include invalid-input();
}
